<template>
  <top></top>
  {{ gameId }}

  <div id="detail_con" class="main_1" style="display: block">
    <a class="mustlogin" href="/server/axjh-1.html"
      ><img v-bind:src="gameInfo.image"
    /></a>
    <div class="gametitle">
      <img v-bind:src="gameInfo.icon" />

      <div>
        <span>{{ this.gameInfo.name }}</span
        ><br />角色扮演
      </div>
      <a class="mustlogin" v-bind:href="this.gameInfo.link">开始玩</a>
    </div>
    <div class="fgline"></div>
    <div class="synopsis">
      <div>
        <img
          src="http://res.lequ.com/Public/h5/gamedetail/tt_yxjj.png"
        />游戏简介
      </div>

      <span v-html="this.gameInfo.content"></span>
      <!-- {{this.gameInfo.content}} -->

      <!-- <p>
        这是一个Q版画风的武侠江湖，<br />
        你将化身为武侠宗师，匡罗子弟，自立门派。<br />
        是除恶惩凶，匡扶正义？<br />
        还是潇洒肆意，成就一代枭雄？<br />
        丰富剧情走向，每一次选择都将成就不一样的你；<br />
        万般武术秘籍，争夺江湖至尊宝座。<br />
        PVP、PVE丰富玩法，满足你一切。<br />
        挂机玩法，解放双手，享受更轻松随性的游戏乐趣。
      </p> -->
    </div>
    <!-- <div class="synopsis">
      <div>
        <img
          src="http://res.lequ.com/Public/h5/gamedetail/tt_yxjt.png"
        />游戏截图
      </div>
    </div> -->

    <!-- <div class="synopsis">
      <div>
        <img
          src="http://res.lequ.com/Public/h5/gamedetail/tt_yxlb.png"
        />游戏礼包
      </div>
    </div> -->
    <!-- <section class="libao-list game-list">
      <ul>
        <li>
          <a href="javascript:;"
            ><i
              ><img src="http://h.lequ.com/Public/Uploads/202101/600a7588d1f2c.png" alt=""
            /></i>
            <dl class="giftdetail">
              <dt><strong>傲笑江湖-冬季礼包</strong></dt>
              <dd class="fenlei">
                <span>剩余：</span>
                <div class="progress">
                  <span class="sr-only">86%</span>
                  <div class="progress-bar" style="width: 86%"></div>
                </div>
              </dd>
              <dd class="content">银两*180，剑术指导*1，刀法指导*1</dd>
            </dl></a
          ><a
            href="javascript:void(0);"
            class="btn-blue popup mustlogin"
            onclick="getcard(2774)"
            >领取</a
          >
        </li>
        <li>
          <a href="javascript:;"
            ><i
              ><img src="http://h.lequ.com/Public/Uploads/202101/600a7588d1f2c.png" alt=""
            /></i>
            <dl class="giftdetail">
              <dt><strong>傲笑江湖-新手礼包</strong></dt>
              <dd class="fenlei">
                <span>剩余：</span>
                <div class="progress">
                  <span class="sr-only">85%</span>
                  <div class="progress-bar" style="width: 85%"></div>
                </div>
              </dd>
              <dd class="content">元宝*15；包子*2；紫禁币*1</dd>
            </dl></a
          ><a
            href="javascript:void(0);"
            class="btn-blue popup mustlogin"
            onclick="getcard(2773)"
            >领取</a
          >
        </li>
      </ul>
    </section> -->
    <div class="cardinfopop">
      <span></span><br />
      长按复制&nbsp;<a href="javascript:close()">[关闭]</a>
    </div>
    <div class="synopsis">
      <div>
        <img
          src="http://res.lequ.com/Public/h5/gamedetail/tt_yxzx.png"
        />游戏攻略
      </div>
    </div>
    <div id="gamezixun_con">
      <ul class="newlist">
        <li v-for="(item, index) in gameNewsList" :key="index">
          <a v-bind:href="'/newsdetail/'+item.id">{{ item.title }}</a>
        </li>

        <div class="loading" v-if="Object.keys(this.gameNewsList).length==0">当前暂无攻略 !</div>
      </ul>
    </div>

    <div id="giftDes2" class="giftDes" style="display: none">
      <div class="giftDes_box">
        <div class="giftDes_box2">
          <div class="giftDes_1"></div>
          <div class="giftDes_2"></div>
          <div class="giftDes_3">剩余：<span class="giftnum"></span></div>
          <div class="giftDes_4">
            <span class="giftDes2colse but2">[关闭]</span>
          </div>
        </div>
      </div>
    </div>
  </div>
    <fnav></fnav>
</template>

<script>
//在组件中获取动态路由参数：
//      方式一：this.$route.params.xxx
//推荐  方式二: props 传参
import top from "@/components/top.vue";
import fnav from "@/components/fnav.vue";


import { getGameInfo, getGameNewsList } from "@/api/game";

export default {
  name: "",
  props: {
    gameId: {
      type: Number,
      required: true,
    },
  },
  components: {
    top,
    fnav,
  },
  data() {
    return {
      //id: this.$route.params.gameId,
      gameInfo: {},
      gameNewsList: {},
    };
  },

  methods: {
    loadGameInfo: async function () {
      const param = { id: this.gameId };

      const { data } = await getGameInfo(param);
      this.gameInfo = data.data;
    },
    loadGameNewsList: async function () {
      const param = { id: this.gameId };
      const { data } = await getGameNewsList(param);
      this.gameNewsList = data.data;

    },
  },
  created() {
    console.log(111);
    this.loadGameInfo();
    this.loadGameNewsList();
    // console.log(this.gameNewsList);
  },
};
</script>

<style>
</style>