import request from '@/utils/request' //导入基础的请求模块

// 获取游戏信息
export const getGameList = (data) => { 
    
    return  request({ 
        method: 'post', 
        url: '/api/gamelist/', 
        data: data,
        // params: params 
    })

}

//获取游戏信息
export const getGameInfo=(data)=>{
    return request({
        method:'post',
        url:'/api/gameinfo',
        data:data,
    })
}

//获取游戏攻略
export const getGameNewsList=(data)=>{
    return request({
        method:'post',
        url:'/api/gamenewslist',
        data:data,
    })
}


